import { Icon } from '@chakra-ui/icon';
import { Box, Text } from '@chakra-ui/layout';
import { StringToIconKeys, STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import CallToAction, {
  CallToActionProps,
} from '@components/CallToAction/CallToAction';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
interface FeatureCardProps {
  icon: StringToIconKeys;
  title: string;
  iconColor: string;
  iconAlignment: 'left' | 'right' | 'center';
  description: string;
  featureColor: string;
  featureAlignment: 'left' | 'right' | 'center';
  cta?: CallToActionProps;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  iconColor,
  iconAlignment = 'center',
  description,
  featureColor,
  featureAlignment = 'center',
  cta,
}) => {
  const { width, size } = cta ?? {};
  return (
    <Box display="flex" flexDirection="column">
      <Box color={iconColor} textAlign={iconAlignment}>
        <Icon as={STRING_TO_ICON_CLASS[icon]} boxSize="60px" />
      </Box>
      <Text
        color={featureColor}
        fontSize="xl"
        fontWeight="700"
        lineHeight="120%"
        textAlign={featureAlignment}
        mt={3}
      >
        {title}
      </Text>
      <Box
        color={featureColor}
        fontSize="md"
        fontWeight="400"
        lineHeight="150%"
        maxWidth="400px"
        textAlign={featureAlignment}
        mt={5}
        className="magnolia-text"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {cta && (
        <CallToAction
          height="100%"
          alignItems="end"
          mt={5}
          mb={0}
          px={0}
          buttonProps={{
            size: size ?? 'md',
            width: width ?? 'fit-content',
            elementAction: ElementActions.OPEN_MODAL,
            elementName: ElementNames.GENERIC_BUTTON,
            elementType: ElementTypes.BUTTON,
          }}
          {...cta}
        />
      )}
    </Box>
  );
};

export default FeatureCard;
