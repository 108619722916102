import { Box, Grid } from '@chakra-ui/layout';
import { CallToActionProps } from '@components/CallToAction/CallToAction';
import Heading from '@components/Heading';
import Container from '@components/LayoutStructure/Container';
import { StringToIconKeys } from '@components/RenderIcon';
import { HeadingElements } from '~/@types/heading';
import { headingSizes } from '~/config/headings';
import FeatureCard from './FeatureCard';
import { Color, getColor } from '@utils/getColor';

export interface Feature {
  title: string;
  description: string;
  featureAlignment: 'left' | 'right' | 'center';
  featureColor?: Color;
  icon: StringToIconKeys;
  iconColor?: Color;
  iconAlignment: 'left' | 'right' | 'center';
  cta?: CallToActionProps;
}

interface FeaturesProps {
  title?: string;
  titleAlignment?: 'center' | 'left' | 'right';
  headingElement?: HeadingElements;
  description?: string;
  color?: Color;
  features: {
    features0: Feature;
    features1?: Feature;
    features2?: Feature;
    features3?: Feature;
    features4?: Feature;
    features5?: Feature;
    '@nodes': string[];
  };
}

const Features: React.FC<FeaturesProps> = ({
  title,
  titleAlignment = 'center',
  headingElement,
  description,
  color,
  features,
}) => {
  const size = headingSizes[headingElement || 'h2'];
  const nodes = features
    ? features['@nodes'].map((node) => features[node])
    : [];
  const columns = nodes.length < 3 ? nodes.length : 3;
  const colorValue = getColor(color?.color, color?.range);
  return (
    <Container>
      <Heading
        headingElement={headingElement}
        headingSize={size}
        title={title || ''}
        withContainer={false}
        textAlign={titleAlignment}
        color={colorValue}
        mt={20}
      />
      <Box
        color={colorValue}
        fontSize="xl"
        fontWeight="400"
        lineHeight="150%"
        textAlign={titleAlignment}
        mx="auto"
        mt={4}
        dangerouslySetInnerHTML={{ __html: description || '' }}
      />
      <Grid
        columnGap="5"
        justifyItems="space-evenly"
        mt={12}
        mb={20}
        rowGap={{ base: '8', lg: '12' }}
        templateColumns={{
          base: 'repeat(1, minmax(0, 1fr))',
          md: `repeat(${columns > 2 ? 2 : columns}, minmax(0, 1fr))`,
          lg: `repeat(${columns}, minmax(0, 1fr))`,
        }}
      >
        {nodes.map((feature) => {
          const {
            title,
            description,
            icon,
            iconColor,
            iconAlignment,
            featureColor,
            featureAlignment,
            cta,
          } = feature;
          const iconColorValue = getColor(iconColor?.color, iconColor?.range);
          const featureColorValue = getColor(
            featureColor?.color,
            featureColor?.range
          );
          return (
            <FeatureCard
              key={title}
              title={title || ''}
              description={description}
              icon={icon}
              iconColor={iconColorValue}
              iconAlignment={iconAlignment}
              featureColor={featureColorValue}
              featureAlignment={featureAlignment}
              cta={cta}
            />
          );
        })}
      </Grid>
    </Container>
  );
};

export default Features;
